export const PLUS_ONE = 'PLUS_ONE';
export const TRUE_FALSE = 'TRUE_FALSE';
export const MOBILE = 'MOBILE';
export const FPSUPDATE = 'FPSUPDATE';
export const ONABOUT = 'ONABOUT';
export const ONPORTFOLIO = 'ONPORTFOLIO';
export const ONCONTACT = 'ONCONTACT';
export const ONPAUSE = 'ONPAUSE';
export const ONSHOW = 'ONSHOW';
export const ONSENT = 'ONSENT';
export const PORTTRUE = 'PORTTRUE';
export const ABTRUE = 'ABTRUE';
export const COTRUE = 'COTRUE';